<template>
    <table class="table table-hover">
        <thead>
        <tr>
            <th>Codice interno</th>
            <th>Codice esterno</th>
            <th>Ultima medical history</th>
            <th>Ultima ablation procedure</th>
            <th>Ultimo follow up</th>
        </tr>
        </thead>
        <tbody id="patient-list">
        <PatientRow
                :patient="patient"
                v-for="patient in patients"
                v-bind:key="patient.id"
        ></PatientRow>
        </tbody>
    </table>
</template>

<script>
    import PatientRow from "./PatientRow";
    export default {
        name: "PatientTable",
        components: { PatientRow },
        props: {
            patients: {
                type: Array,
                required: true
            }
        }
    };


</script>
