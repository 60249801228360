<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
            <div class="row margin-bottom-20">
                <div class="col-xs-12 col-md-3">
                    <h1 class="h2 margin-0">
                        <i class="las la-users margin-bottom-5" style="font-size:40px;"></i>
                        Pazienti
                    </h1>
                </div>
                <div class="col-xs-12 col-md-3 text-right">
                  <ModelListSelect class="form-control" id="hospital-options" name="hospital-options" :list="hospitals" option-value="id" option-text="name" v-model="selectedHospital">Ospedale</ModelListSelect>
                </div>
                <div class="col-xs-12 col-md-6 text-right">
                    <button
                            class="btn btn-default btn-action-mobile btn-undo-mobile"
                            @click.prevent.stop="$router.push({name:'research_project_detail',
                                         params: { research_project_id: $route.params.research_project_id}})"
                    >
                        <i class="las la-undo"></i>
                        <span class="button-text">Indietro</span>
                    </button>
                </div>
            </div>
            <div class="table-responsive" v-if="patients.length > 0">
                <PatientTable :patients="patients"></PatientTable>
            </div>
            <div v-else>
                <span class="text-muted">Non sono presenti pazienti per questo progetto di ricerca</span>
            </div>
            <div class="text-center">
                <Pagination
                    v-if="enabledPagination"
                    v-show="numberPagesPatient > 1"
                    :page-count="numberPagesPatient"
                    :click-handler="paginateCallback" />
            </div>
        </div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import {Utils} from "../common/utils";
    import PatientTable from "../components/patients/PatientTable";
    import Pagination from "../components/common/Pagination";
    import {LIMIT_RECORDS_API} from "../common/constants";
    import {getAllHospital, getPatients} from "../api";
    import {ModelListSelect} from "vue-search-select";

    export default {
        name: "Patients",
        components: { PatientTable, ContainerWithMenu, Pagination, ModelListSelect},
        data: function() {
            return {
                patients: [],
                hospitals: [],
                selectedHospital: null,
                allHospitalOption: {
                  id: 0,
                  name: "Tutti gli ospedali"
                },
                countPatient: 0,
                limitPatient: LIMIT_RECORDS_API,
                currentPagePatient: 1,
                loading: false,
                newPatientModalVisible: false,
                enabledPagination: false
            };
        },
        mounted() {
            this.selectedHospital = this.allHospitalOption
            this.getPatients()
            this.getHospitals()
        },
        computed: {
            numberPagesPatient: function () {
                return Utils.getNumberPageByCountLimit(this.countPatient, this.limitPatient);
            }
        },
        methods: {
            paginateCallback(pageNum) {
                this.getPatients(pageNum, this.selectedHospital.id)
            },
            async getPatients(page = 1, selectedHospital = 0) {
                this.loading = true;
                try {
                    let patientsResponse = await getPatients(this.$route.params.research_project_id, page, selectedHospital);
                    this.countPatient = patientsResponse.count;
                    this.patients = patientsResponse.results;

                    this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            async getHospitals() {
              this.loading = true;
              try {
                this.hospitals = await getAllHospital()
                this.hospitals.unshift(this.allHospitalOption)
                console.log(this.hospitals)
                this.loading = false
              } catch (e) {
                this.loading = false;
                console.log(e)
              }
            }
        },
        watch: {
          selectedHospital: function(value) {
            this.getPatients(1, value.id)
            this.enabledPagination = false;
            this.$nextTick(() => {
              this.enabledPagination = true;
            });
          }
        }
    };
</script>
