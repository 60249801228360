<template>
    <tr class="clickable-row">
        <td><a href="" @click.prevent.stop>{{patient.fingerprint}}</a></td>
        <td><a href="" @click.prevent.stop>{{patient.code_external}}</a></td>
        <td><a href="" @click.prevent.stop>{{getLongDateFromISO(patient.last_medical_history_date)}}</a></td>
        <td><a href="" @click.prevent.stop>{{getLongDateFromISO(patient.last_ablation_procedure_date)}}</a></td>
        <td><a href="" @click.prevent.stop>{{getLongDateFromISO(patient.last_follow_up_date)}}</a></td>
    </tr>
</template>

<script>
    import {Utils} from "../../common/utils";

    export default {
        name: "PatientRow",
        props: {
            patient: {
                type: Object,
                require: true
            }
        },
        methods: {
            getLongDateFromISO(date){
                return Utils.getLongDateFromISO(date)
            }
        }
    };
</script>
